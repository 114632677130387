<template>
  <div>
    <b-container fluid>
      <b-row style="padding-top: 10px">
        <b-col cols="12" class="pageTitleRwd">
          <span class="pageTitle">{{ $t("lineChart.title") }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-breadcrumb
          :items="breadcrumbItems"
          class="bread-crumb"
          style="font-size: 15px"
        />
      </b-row>
      <b-row>
        <b-col>
          <TimeSeparator
            :date-type="dateType"
            :date-type-clicked-idx="dateTypeClickedIdx"
            @date-type-click="handleClickDateType"
          />
        </b-col>
      </b-row>
      <b-row class="my-4 d-flex justify-content-center align-items-center">
        <b-col
          class="mb-1 d-flex flex-row align-items-center flex-wrap justify-content-start"
          cols="12"
          sm="4"
        >
          <div class="select-time">{{ $t("lineChart.selectDate") }}</div>
          <datepicker
            placeholder="選擇日期"
            v-model="date"
            :format="format"
          ></datepicker>
        </b-col>
        <b-col
          class="mb-1 d-flex flex-row align-items-center flex-wrap justify-content-start"
          cols="12"
          sm="4"
          v-if="isTime"
        >
          <div class="select-time">{{ $t("lineChart.startTime") }}</div>
          <vue-clock-picker
            v-model="startTime"
            placeholder=" -- : --"
            active-color="#007bff"
          ></vue-clock-picker>
        </b-col>
        <b-col
          class="mb-1 d-flex flex-row align-items-center flex-wrap justify-content-start"
          cols="12"
          sm="4"
          v-if="isTime"
        >
          <div class="select-time">{{ $t("lineChart.endTime") }}</div>
          <vue-clock-picker
            v-model="endTime"
            placeholder=" -- : --"
            @close="handleTimeAry"
            active-color="#007bff"
          ></vue-clock-picker>
        </b-col>
        <b-col
          class="mb-1 d-flex flex-row align-items-center flex-wrap justify-content-start"
          v-if="dateTypeClickedIdx > 1"
          cols="12"
          sm="4"
        >
          <div class="select-time">{{ $t("lineChart.endDate") }}</div>
          <datepicker
            placeholder="結束日期"
            v-model="endDate"
            :format="format"
          ></datepicker>
        </b-col>
      </b-row>
      <b-table-simple>
        <b-thead>
          <b-tr>
            <b-th class="thead-font-shrink">{{
              $t("lineChart.tableTr.id")
            }}</b-th>
            <b-th class="thead-font-shrink" style="white-space: nowrap">{{
              $t("lineChart.tableTr.device")
            }}</b-th>
            <b-th
              class="d-flex flex-nowrap thead-font-shrink"
              style="white-space: nowrap"
              >{{ $t("lineChart.tableTr.curveColor") }}</b-th
            >
            <b-th class="thead-font-shrink" style="white-space: nowrap">{{
              $t("lineChart.tableTr.curveType")
            }}</b-th>
            <b-th class="thead-font-shrink">{{
              $t("lineChart.tableTr.func")
            }}</b-th>
          </b-tr>
        </b-thead>
        <table-tr
          @childEmitData="getTrData"
          @childEmitChangeCurveType="parentOnChangeCurveType"
        />
      </b-table-simple>
      <div id="new-chart"></div>
      <!-- <highChartVue ref="highcharts" :options="chartOptions" /> -->
    </b-container>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import TimeSeparator from '../components/TimeSeparator'
import VueClockPicker from '@pencilpix/vue2-clock-picker'
import '@pencilpix/vue2-clock-picker/dist/vue2-clock-picker.min.css'
import Highcharts from 'highcharts'
import TableTr from '../components/TableTr'
export default {
  name: 'LineChart',
  components: {
    TimeSeparator,
    Datepicker,
    VueClockPicker,
    TableTr
    // highChartVue: Chart
  },
  data () {
    return {
      breadcrumbItems: [
        {
          text: '首頁',
          to: {
            name: 'realtime'
          }
        },
        {
          text: '電廠電量比較',
          active: true
        }
      ],
      dateType: [
        { title: '分' },
        { title: '時' },
        { title: '日' },
        { title: '月' },
        { title: '年' }
      ],
      dateTypeClickedIdx: 0,
      date: '',
      endDate: '',
      startTime: '00:00',
      endTime: '00:00',
      isTime: true,
      xAxisAry: [],
      idChart: {},
      fakeSeries: {
        series: [
          {
            data: [1, 2, 9, 6, 7, 8] // addSeries 只能新增物件
          },
          {
            data: [8, 3, 5, 7, 9, 5]
          },
          {
            data: [3, 5, 6, 8, 4, 3]
          }
        ]
      },
      format: 'yyyy-MM-dd',
      chartOptions: {
        series: [
          {
            data: [1, 2, 3] // sample data
          }
        ]
      }
    }
  },
  computed: {
    xAxisLen () {
      return (
        parseInt(this.endTime.split(':')[0]) -
        parseInt(this.startTime.split(':')[0])
      )
    }
  },
  methods: {
    handleClickDateType (idx) {
      this.dateTypeClickedIdx = idx
      if (this.dateTypeClickedIdx === 0 || this.dateTypeClickedIdx === 1) {
        this.isTime = true
      } else {
        this.isTime = false
      }
      if (this.dateTypeClickedIdx === 2) {
        this.format = 'yyyy-MM-dd'
      } else if (this.dateTypeClickedIdx === 3) {
        this.format = 'yyyy-MM'
      } else if (this.dateTypeClickedIdx === 4) {
        this.format = 'yyyy'
      }
    },
    handleTimeAry () {
      return new Promise((resolve) => {
        const ary = []
        let newStart = this.startTime.split(':')[0] * 1
        let newEnd = this.endTime.split(':')[0] * 1
        if (newEnd - newStart <= 0) {
          newEnd - newStart < 0
            ? alert('起始時間不得大於結束時間')
            : alert('未選擇起始時間')
          return Promise.reject(
            new Error('xAxis length is not greater than 0')
          )
        }
        for (let i = 1; i < newEnd - newStart; i++) {
          ary.push(newStart + i)
        }
        ary.unshift(newStart)
        ary.push(newEnd)
        this.xAxisAry = ary
        resolve()
      })
    },
    showChart () {
      this.handleTimeAry().then(() => {
        this.idChart = Highcharts.chart('new-chart', {
          charts: {
            type: 'line'
          },
          title: '',
          xAxis: {
            // type: 'datetime',
            // dateTimeLabelFormats: {
            //   hour: '%H:%M'
            // },
            // max: 100,
            // min: this.xAxisAry[0],
            title: {
              text: '小時'
            },
            categories: this.xAxisAry,
            max: this.xAxisLen
          },
          yAxis: {
            categories: [0, 1, 2, 3, 4, 5, 6]
          },
          series: [
            {
              data: [
                1.4654, 2.456456, 3.145, 4.456, 1.01014, 13, 12, 4, 2, 5, 7, 12
              ],
              // pointInterval: 50 * 1000
              name: '',
              color: ''
            }
          ]
        })
      })
    },
    // parentColor ($event, idx) {
    //   this.idChart.series[idx].update({
    //     color: $event
    //   })
    // },
    parentOnChangeCurveType ($event) {
      console.log($event, this.idChart.series)
      this.idChart.series[1].update({
        type: $event
      })
    },
    // parentAdd (rowObj) {
    //   this.idChart.addSeries({
    //     data: [2, 3, 4, 5, 3, 9, 6, 12, 4]
    //   })
    //   this.rowNum.push(rowObj)
    // },
    async getTrData (trs) {
      console.log(trs) // trs 要去GET後端 data
      // const { curveVal, facilityVal, factoryVal, seriesColor, typeVal } = trs
      await this.axios
        .get('https://jsonplaceholder.typicode.com/todos/1')
        .then((res) => {
          console.log(res)
          // res 資料去更新 highcharts 圖表，series and xAxis
          // 暫時先用假資料
          while (this.idChart.series.length > 0) {
            this.idChart.series[0].remove()
          }
          for (let i = 0; i < trs.length; i++) {
            this.idChart.addSeries(this.fakeSeries.series[i])
          }
        })
    }
  },
  mounted () {
    this.idChart = Highcharts.chart('new-chart', {
      title: '',
      series: [{}]
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/LineChart.scss";
</style>
