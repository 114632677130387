<template>
    <b-tbody>
      <b-tr v-for="(tr, idx) in trs" :key="tr.key">
        <b-td>
          <b-button size="sm" @click="addTr">
            <b>+</b>
          </b-button>
        </b-td>
        <b-td class="d-flex flex-row align-items-center flex-wrap three-selects">
          <div class="factory mr-2 mb-1">
            <b-form-select :options="factorySiteName" v-model="tr.factoryVal" @change="factoryInvss(idx)" size="sm"></b-form-select>
          </div>
          <div class="types mr-2 mb-1">
            <b-form-select :options="rowArray.typeOpts[idx].invs" v-model="tr.typeVal" size="sm"></b-form-select>
          </div>
          <div class="facility mr-2 mb-1">
            <b-form-select :options="rowArray.facilityOpts" v-model="tr.facilityVal" size="sm"></b-form-select>
          </div>
        </b-td>
        <b-td>
          <swatches v-model="tr.seriesColor"></swatches>
        </b-td>
        <b-td>
          <b-form-select :options="rowArray.curveOpts" @input="changeCurveType" v-model="tr.curveVal" size="sm"></b-form-select>
        </b-td>
        <b-td>
          <b-button variant="danger" size="sm" @click="deleteTr(idx)">{{ $t("tableTr.delete") }}</b-button>
        </b-td>
      </b-tr>
      <b-tr class="text-center td-no-border small-btn">
        <b-td class="text-left">
          <b-button @click="emitData">{{ $t("tableTr.show") }}</b-button>
        </b-td>
      </b-tr>
    </b-tbody>
</template>
<script>
import Swatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.min.css'
import { mapState } from 'vuex'
export default {
  name: 'TableTr',
  props: {
    options: {
      type: Object
    }
  },
  components: {
    Swatches
  },
  data () {
    return {
      rowArray: {
        factoryOpts: [
          {
            value: 1,
            text: 'factory1'
          },
          {
            value: 2,
            text: 'factory2'
          },
          {
            value: 3,
            text: 'factory3'
          }
        ],
        typeOpts: [
          {
            invs: []
          }
        ],
        facilityOpts: [
          'IRR', 'PC IIMSD', 'AV DSPA'
        ],
        curveOpts: [
          'spline', 'column', 'areaspline', 'bar'
        ]
      },
      trs: [
        {
          factoryVal: '鳳山圳滯洪池',
          typeVal: 'INV1',
          facilityVal: '',
          curveVal: '',
          seriesColor: ''
        }
      ]
    }
  },
  computed: {
    ...mapState([
      'factoryData'
    ]),
    factorySiteName () {
      let sitename = []
      for (let i = 0; i < this.factoryData.length; i++) {
        // sitename.push(this.factoryData[i].factoryName)
        let data = {
          value: this.factoryData[i].factoryName,
          text: this.factoryData[i].factoryName
        }
        sitename.push(data)
      }
      this.factoryInvss(0)
      return sitename
    }
  },
  methods: {
    factoryInvss (idx) {
      this.rowArray.typeOpts[idx].invs = []
      for (let i = 0; i < this.factoryData.length; i++) {
        if (this.trs[idx].factoryVal === this.factoryData[i].factoryName) {
          for (let j = 0; j < this.factoryData[i]['AC_kWh'].length; j++) {
            this.rowArray.typeOpts[idx].invs.push(`INV${j + 1}`)
          }
        }
      }
      console.log(this.rowArray.typeOpts[idx])
    },
    addTr () {
      this.rowArray.typeOpts.push({
        invs: []
      })
      this.trs.push({
        factoryVal: '鳳山圳滯洪池',
        typeVal: 'INV1',
        facilityVal: ''
      })
      this.factoryInvss(this.trs.length - 1)
    },
    deleteTr (idx) {
      if (this.trs.length <= 1) {
      } else {
        this.trs.splice(idx, 1)
      }
    },
    emitData () {
      this.$emit('childEmitData', this.trs)
    },
    changeCurveType (v) {
      this.$emit('childEmitChangeCurveType', v)
    }
  }
}
</script>
<style lang="scss">
.color-picker{
  .sb-color_picker-current{
    width: 40px;
    height: 40px;
    border: 1px;
    .sb-color_picker-current-color{
      width: 100%;
    }
  }
  .sb-color_picker-current-icon{
    display: none;
  }
  .sb-color_picker-picker{
    z-index: 100;
  }
}
.td-no-border{
  td{
    border: none !important;
  }
}
@media screen and (max-width: 590px) { // 為了三個select
  .three-selects{
    flex-direction: column !important;
  }
}
@media screen and (max-width: 480px) {
  .small-btn{
    button{
      font-size: 12px;
      white-space: nowrap;
      padding: 5px;
    }
  }
}
</style>
